import React from "react"
import { Box, Drawer } from "@mui/material"
import PerfectScrollbar from "react-perfect-scrollbar"
import Menu from "layout/MainLayout/Menu/Menu"
import {
    DRAWER_WIDTH_LARGE_SCREEN,
    DRAWER_WIDTH_SMALL_SCREEN,
    TOP_BAR_HEIGHT_LARGE_SCREEN,
    TOP_BAR_HEIGHT_SMALL_SCREEN,
} from "layout/MainLayout/constants"
import { styled } from "@mui/material/styles"

export const SideBar = () => {
    return (
        <StyledBox component="nav">
            <Drawer variant="persistent" anchor="left" open={true} ModalProps={{ keepMounted: true }} color="inherit">
                <PerfectScrollbar component="div">
                    <Menu />
                </PerfectScrollbar>
            </Drawer>
        </StyledBox>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    width: DRAWER_WIDTH_LARGE_SCREEN,
    [theme.breakpoints.down("lg")]: {
        width: DRAWER_WIDTH_SMALL_SCREEN,
    },
    "& .MuiDrawer-root": {
        width: DRAWER_WIDTH_LARGE_SCREEN,
        [theme.breakpoints.down("lg")]: {
            width: DRAWER_WIDTH_SMALL_SCREEN,
        },
    },
    "& .MuiDrawer-paper": {
        overflow: "hidden",
        marginTop: TOP_BAR_HEIGHT_LARGE_SCREEN,
        width: DRAWER_WIDTH_LARGE_SCREEN,
        [theme.breakpoints.down("lg")]: {
            width: DRAWER_WIDTH_SMALL_SCREEN,
            marginTop: TOP_BAR_HEIGHT_SMALL_SCREEN,
        },
        zIndex: 1099,
    },
}))

export default SideBar
