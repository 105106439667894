import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import { Collapse, List, Paper, useTheme } from "@mui/material"
import { MenuDTO, MenuNodeDTO, MenuRootDTO } from "generated/models"
import { produce } from "immer"
import { MenuListItem } from "layout/MainLayout/Menu/MenuListItem"
import { useLayoutContext } from "layout/MainLayout/LayoutContext"
import { useAnalytics } from "shared/analytics/AnalyticsContext"
import DomUtil from "shared/util/DomUtil"
import { log } from "shared/util/log"
import { styled } from "@mui/material/styles"
import { MAIN_PADDING_LARGE_SCREEN, MAIN_PADDING_SMALL_SCREEN } from "layout/MainLayout/constants"
import {
    FONT_WEIGHT_BOLD,
    FONT_WEIGHT_MEDIUM,
    FONT_WEIGHT_REGULAR,
    FONT_WEIGHT_SEMI_BOLD,
} from "styles/theme/constants"
import { isMenuLeaf, isNode } from "shared/service/MenuUtil"
import { MenuLeafDTO } from "domain/types"

export const Menu = () => {
    const theme = useTheme()
    const { userConfigs } = useLayoutContext()
    const menu = useMemo(() => userConfigs.menu ?? { root: [] }, [userConfigs])

    const { navigate, pathname } = useLayoutContext()

    const analyticsService = useAnalytics()

    const getExpandedNodes = useCallback(() => {
        const result = new Set<string>()
        const path = findPathInTree(pathname, menu.root)

        if (path.length > 0) {
            path.pop() // remove the last element, which is the leaf
            path.forEach((nodePath) => result.add(nodePath))
        }

        return result
    }, [pathname, menu.root])

    /**
     * We use the path attribute of the node to identify it.
     */
    const [expandedNodes, setExpandedNodes] = useState(getExpandedNodes)

    const getSelectedLeaf = useCallback(() => {
        const path = findPathInTree(pathname, menu.root)

        if (path.length > 0) {
            return path[path.length - 1]
        }

        return undefined
    }, [pathname, menu.root])

    /**
     * Again, we use the path attribute of the leaf to identify it.
     */
    const [selectedLeaf, setSelectedLeaf] = useState<string | undefined>(getSelectedLeaf)

    const updateSelectedLeafIndicator = useCallback((animate: boolean) => {
        // using setTimeout to break the callstack and ensure that elements have actually been rendered before we try to adjust the indicator position
        setTimeout(() => {
            const collapsibleNodeClassName = "MuiCollapse-root"
            const indicators = document.querySelectorAll(
                ".main-menu .selected-leaf-indicator",
            ) as unknown as HTMLElement[]
            indicators.forEach((indicator) => {
                const rootMenuNode = indicator.closest("." + collapsibleNodeClassName) as HTMLElement
                const selectedItem = rootMenuNode.querySelector(".menu-list-item.selected") as HTMLElement

                let areAllParentNodesExpanded = true
                if (selectedItem) {
                    const collapsibleParentNodes = DomUtil.getParentsByClass(selectedItem, collapsibleNodeClassName)
                    areAllParentNodesExpanded = collapsibleParentNodes.every((parent) => parent.offsetHeight > 0)
                }

                // if indicator belongs to the currently selected menu node AND the node is visible because all parent nodes are expanded: update so that it is visible at the correct position
                if (selectedItem && areAllParentNodesExpanded) {
                    // Note that this only works because the menu has no open/close animation; otherwise we would need a more sophisticated approach
                    // Another requirement is that the collapsible component has to be set to overflow hidden. Otherwise, the indicator might be visible outside the menu when collapsing a menu node
                    const previousPosition = getTranslate3dYValue(indicator)
                    const newPosition = selectedItem.offsetTop + 11
                    const difference = Math.abs(previousPosition - newPosition)
                    // longer transitions for larger distances
                    const transitionDurationMs = animate ? Math.min(750, difference * 8) : 0
                    indicator.style.transition = "all " + transitionDurationMs + "ms ease-in-out"
                    indicator.style.transform = "translate3d(0, " + newPosition + "px, 0)"
                    indicator.style.height = "5px"
                    indicator.style.opacity = "100%"
                }
                // else: hide indicator
                else {
                    indicator.style.transform = "translate3d(0, 0, 0)"
                    indicator.style.height = "0px"
                    indicator.style.opacity = "0%"
                }
            })
        }, 1)
    }, [])

    /**
     * Set selected leaf and expanded nodes according to navigation path
     */
    useEffect(() => {
        const id = getSelectedLeaf()
        const expandedNodes = getExpandedNodes()
        if (id) {
            setSelectedLeaf(id)
            setExpandedNodes(expandedNodes)
            updateSelectedLeafIndicator(false)
        }
    }, [getExpandedNodes, getSelectedLeaf, updateSelectedLeafIndicator])

    const makeNodeClickHandler = (id: string) => () => {
        setExpandedNodes(
            produce((draft) => {
                if (draft.has(id)) {
                    draft.delete(id)
                } else {
                    draft.add(id)
                }
            }),
        )
        // animating this would feel weird if we're only expanding/collapsing a parent node
        updateSelectedLeafIndicator(false)
    }

    const makeLeafClickHandler = (id: string, path: string) => (event: React.MouseEvent) => {
        // prevent the default a href navigation behaviour
        event.preventDefault()

        setSelectedLeaf(id)
        navigate(path)
        updateSelectedLeafIndicator(true)

        const printablePath = findPrintablePathInTree(path, menu.root)
        analyticsService
            .trackMenuNavigation(printablePath[0], printablePath.join(" / "))
            .catch((err) => log.debug("Failed to track menu navigation", err))
    }

    const getTranslate3dYValue = (element: HTMLElement): number => {
        try {
            const style = window.getComputedStyle(element)
            const transform = style.transform
            const matrix = transform.replace(/[^0-9\-.,]/g, "").split(",")
            if (matrix && Array.isArray(matrix)) {
                return typeof matrix[5] === "string" ? (matrix[5] as unknown as number) : 0
            }
        } catch (e) {
            // ignore
        }
        return 0
    }

    const renderMenuElements = (menuItems: MenuRootDTO) => {
        return (
            <StyledPaper elevation={0} className={"main-menu"}>
                {renderMenuElementsRec(0, menuItems.root)}
            </StyledPaper>
        )
    }

    const renderMenuElementsRec = (level: number, menuItems: MenuDTO[], dense = false) => {
        return (
            <List
                dense={dense}
                className={"menu-list level-" + level}
                sx={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    position: level == 1 ? "relative" : "inherit",
                    "&:after":
                        level > 0
                            ? {
                                  content: "''",
                                  position: "absolute",
                                  left: "10px",
                                  top: 0,
                                  height: "100%",
                                  width: "1px",
                                  background: theme.palette.border.subtle,
                              }
                            : {},
                }}
            >
                {menuItems.map((item) => renderMenuDTO(level, item))}
                {level == 1 && (
                    <div
                        className={"selected-leaf-indicator"}
                        style={{
                            borderRadius: "5px",
                            zIndex: 1,
                            content: "''",
                            position: "absolute",
                            left: "8px",
                            top: "0",
                            height: "0px",
                            width: "5px",
                            background: theme.palette.primary.main,
                            transition: "all 0.5s ease-in-out",
                        }}
                    ></div>
                )}
            </List>
        )
    }

    const renderMenuDTO = (level: number, item: MenuDTO) => {
        if (isMenuLeaf(item)) {
            return renderLeaf(level, item)
        } else if (isNode(item)) {
            return renderNode(level, item)
        }
    }

    const renderLeaf = (level: number, item: MenuLeafDTO) => {
        return (
            <MenuListItem
                key={item.title}
                type="leaf"
                menuLeafDTO={item}
                level={level}
                isSelected={item.path === selectedLeaf}
                onClick={makeLeafClickHandler(item.path, item.path)}
            />
        )
    }

    const renderNode = (level: number, item: MenuNodeDTO) => {
        return (
            <Fragment key={item.path + "_node"}>
                <MenuListItem
                    key={item.title}
                    type="node"
                    menuNodeDTO={item}
                    level={level}
                    isExpanded={expandedNodes.has(item.path)}
                    onClick={makeNodeClickHandler(item.path)}
                />
                <Collapse in={expandedNodes.has(item.path)} timeout={0} unmountOnExit sx={{ overflow: "hidden" }}>
                    {renderMenuElementsRec(level + 1, item.items, true)}
                </Collapse>
            </Fragment>
        )
    }

    return renderMenuElements(menu)
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: MAIN_PADDING_LARGE_SCREEN,
    paddingTop: "20px",
    paddingRight: "2px",
    [theme.breakpoints.down("lg")]: {
        padding: MAIN_PADDING_SMALL_SCREEN,
    },
    "& .MuiListItem-root": {
        padding: 0,
    },
    "& > .MuiList-root > .MuiListItem-root:first-of-type": {
        marginTop: "-15px",
        paddingTop: 0,
        [theme.breakpoints.down("lg")]: {
            marginTop: "-10px",
        },
    },
    "& .MuiListItemButton-root": {
        lineHeight: "150%",
        paddingRight: "5px",
        "&:hover": {
            backgroundColor: "transparent",
            fontWeight: FONT_WEIGHT_SEMI_BOLD,
            transition: "all 0.1s ease-in-out",
        },
    },
    "& .level-0 .MuiListItemButton-root": {
        paddingBottom: "2px",
        paddingTop: "8px",
        fontWeight: FONT_WEIGHT_MEDIUM,
        [theme.breakpoints.down("lg")]: {
            paddingBottom: "6px",
            paddingTop: "4px",
        },
    },
    "& .level-0 span": {
        color: "rgba(54, 72, 125, 0.95)",
        fontWeight: FONT_WEIGHT_BOLD,
    },
    "& .level-1 span": {
        fontWeight: FONT_WEIGHT_MEDIUM,
    },
    "& *": {
        fontSize: "13px!important",
    },
    "& .level-1 .MuiListItemButton-root": {
        paddingBottom: "1px",
        paddingTop: "1px",
        fontWeight: FONT_WEIGHT_REGULAR,
        [theme.breakpoints.down("lg")]: {
            paddingBottom: "0px",
            paddingTop: "0px",
        },
    },
    "& .MuiSvgIcon-root": {
        color: "rgba(54, 72, 125, 0.8)",
        height: "20px",
        marginTop: "-1px",
        width: "20px",
    },
    "& .MuiListItemIcon-root": {
        minWidth: "28px",
    },
    "& .selected > .MuiListItemButton-root": {
        marginRight: "8px",
        backgroundColor: theme.palette.primaryShades[50],
        borderRadius: "5px",
    },
    "& .selected span": {
        color: theme.palette.primary.main,
        fontWeight: FONT_WEIGHT_BOLD,
    },
}))

const findPathInTree = (path: string, tree: MenuDTO[]): string[] => {
    for (const item of tree) {
        if (isMenuLeaf(item)) {
            if (item.path === path) {
                return [item.path]
            }
        } else if (isNode(item)) {
            const result = findPathInTree(path, item.items)
            if (result.length > 0) {
                return [item.path, ...result]
            }
        }
    }

    return []
}

const findPrintablePathInTree = (path: string, tree: MenuDTO[]): string[] => {
    for (const item of tree) {
        if (isMenuLeaf(item)) {
            if (item.path === path) {
                return [item.title]
            }
        } else if (isNode(item)) {
            const result = findPrintablePathInTree(path, item.items)
            if (result.length > 0) {
                return [item.title, ...result]
            }
        }
    }

    return []
}

export default Menu
