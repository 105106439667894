import store from "shared/redux/store"
import { post } from "shared/service/api"
import { LoadConfigDTO } from "generated/models"
import { PageConfigDTO } from "generated/models"

const loadPageConfig = async (path: string, baseURL?: string): Promise<PageConfigDTO> => {
    const appContext = store.getState().appContext.appContext
    const payload: LoadConfigDTO = {
        appContext,
    }
    return await post<any>(path, payload, { baseURL })
}

const PageService = { loadPageConfig }
export default PageService
