import React, { useMemo, useRef, useState } from "react"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import { Autocomplete, Box, Paper, TextField, ThemeProvider, useTheme } from "@mui/material"
import { useLayoutContext } from "../LayoutContext"
import CustomTheme from "styles/theme/CustomTheme"
import { TOP_BAR_HEIGHT_LARGE_SCREEN, TOP_BAR_HEIGHT_SMALL_SCREEN } from "layout/MainLayout/constants"
import { styled } from "@mui/material/styles"
import { AdvertiserTree } from "generated/models"

interface Option {
    advertiserId: number
    campaignId?: number
}

interface OptionDetails {
    label: string
    disabled: boolean
}

type OptionKey = string

interface Options {
    options: Option[]
    optionDetails: Map<OptionKey, OptionDetails>
}

export const AdvertiserSelector = () => {
    const {
        appContextState: appContextState,
        onAdvertiserCampaignSelected,
        userConfigs: { tree },
    } = useLayoutContext()
    const appContext = appContextState.appContext
    const theme = useTheme()

    const selectedOption = useMemo(() => {
        return {
            advertiserId: appContext.advertiserId,
            campaignId: appContext.campaignId,
        }
    }, [appContext.advertiserId, appContext.campaignId])

    const { options, optionDetails } = useMemo(() => {
        const advertiserTree = tree ?? { root: [] }
        return getOptionsFromTreeNodes(advertiserTree.root)
    }, [tree])
    const inputRef = useRef<HTMLInputElement>(null)

    // increase width if there are very long labels
    const width = calculateWidth(optionDetails)
    const [isFocused, setIsFocused] = useState(false)

    return (
        <CustomTheme paletteMode="dark" disableCssBaseline>
            <StyledBox className="advertiser-selector" width={width} isFocused={isFocused}>
                <Box
                    className={"non-focused-content"}
                    onClick={() => {
                        if (inputRef.current) {
                            inputRef.current.focus()
                        }
                        setIsFocused(true)
                    }}
                >
                    <Box className={"selected-path"}>
                        {appContext.advertiserName}
                        <span>{appContext.campaignName ? " / " : ""}</span>
                        {appContext.campaignName}
                    </Box>
                    <ExpandMoreRoundedIcon />
                </Box>
                {/* This Autocomplete is hidden when it's not focused so that we can align the arrow differently (at the end of the text); it will only be visible when it's focused and we'll manually have to make sure that the layout matches
                the other content that is displayed when we don't have the focus */}
                <Autocomplete
                    options={options}
                    size="small"
                    blurOnSelect
                    openOnFocus
                    selectOnFocus
                    getOptionDisabled={(option) => optionDetails.get(getOptionKey(option)).disabled}
                    value={selectedOption}
                    disableClearable
                    onChange={(event, newValue) => {
                        onAdvertiserCampaignSelected(newValue?.advertiserId, newValue?.campaignId)
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    getOptionLabel={(option) => optionDetails.get(getOptionKey(option))?.label ?? "No entries"}
                    renderOption={(props, option) => {
                        const text = optionDetails.get(getOptionKey(option))?.label
                        const parts = text?.split("/")
                        const renderedParts = parts.reduce((acc, part, index) => {
                            acc.push(part)
                            if (index !== parts.length - 1) {
                                acc.push(<span key={index}>/</span>)
                            }
                            return acc
                        }, [])
                        return <li {...props}>{renderedParts}</li>
                    }}
                    slotProps={{
                        popper: {
                            sx: {
                                "& .MuiAutocomplete-option": {
                                    paddingLeft: "8px!important",
                                    whiteSpace: "nowrap",
                                },
                                "& span": {
                                    padding: "0 5px 0 7px",
                                    opacity: 0.5,
                                },
                            },
                        },
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.advertiserId === value.advertiserId && option.campaignId === value.campaignId
                    }
                    popupIcon={<ExpandMoreRoundedIcon sx={{ color: theme.palette.topBar.color }} />}
                    PaperComponent={CustomPaper}
                    renderInput={(params) => (
                        <TextField {...params} InputProps={{ ...params.InputProps }} inputRef={inputRef} />
                    )}
                    sx={{
                        opacity: isFocused ? 1 : 0,
                    }}
                />
            </StyledBox>
        </CustomTheme>
    )
}

const getOptionKey = (option: Option): OptionKey => {
    return option.advertiserId + (option.campaignId ? "-" + option.campaignId : "")
}

const getOptionsFromTreeNodes = (treeNodes: AdvertiserTree[]): Options => {
    const options: Option[] = []
    const optionDetails: Map<OptionKey, OptionDetails> = new Map()

    treeNodes.forEach((node) => {
        // For each advertiser, create an option without a campaignId
        const option: Option = {
            advertiserId: node.value,
        }
        options.push(option)
        optionDetails.set(getOptionKey(option), {
            label: node.label,
            disabled: node.disabled,
        })

        // If there are campaigns under this advertiser, create options for each campaign
        if (node.children) {
            node.children.forEach((childNode) => {
                const option: Option = {
                    advertiserId: node.value,
                    campaignId: childNode.value,
                }
                options.push(option)
                optionDetails.set(getOptionKey(option), {
                    label: node.label + " / " + childNode.label,
                    disabled: childNode.disabled,
                })
            })
        }
    })

    return {
        options: options,
        optionDetails: optionDetails,
    }
}

const calculateWidth = (optionDetails: Map<OptionKey, OptionDetails>): number => {
    let maxLabelLength = 0

    optionDetails.forEach((details) => {
        const labelLength = details.label.length
        if (labelLength > maxLabelLength) {
            maxLabelLength = labelLength
        }
    })

    const widthPerCharacter = 9
    const minWidth = 200
    const maxWidth = 600
    return Math.min(Math.max(minWidth, maxLabelLength * widthPerCharacter), maxWidth)
}

const CustomPaper = (props) => {
    return (
        <Paper
            elevation={8}
            {...props}
            sx={{
                "& .MuiAutocomplete-listbox": {
                    // increase maximum dropdown list height
                    maxHeight: "calc(90vh - " + TOP_BAR_HEIGHT_LARGE_SCREEN + ")",
                },
            }}
        />
    )
}

interface StyledBoxProps {
    isFocused: boolean
}

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isFocused",
})<StyledBoxProps>(({ theme, width, isFocused }) => ({
    position: "relative",
    cursor: "pointer",
    // overflow: 'hidden',
    "& .non-focused-content": {
        position: "absolute",
        left: "8px",
        top: "15px",
        zIndex: 2,
        width: width,
        maxWidth: "600px",
        height: TOP_BAR_HEIGHT_LARGE_SCREEN,
        display: isFocused ? "none" : "flex",
        flexDirection: "row",
        [theme.breakpoints.down("lg")]: {
            height: TOP_BAR_HEIGHT_SMALL_SCREEN,
        },
        "& .selected-path": {
            whiteSpace: "nowrap",
            maxWidth: "600px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            marginRight: theme.spacing(1),
            "& span": {
                padding: "0 5px 0 7px",
                opacity: 0.5,
            },
        },
        "& .MuiSvgIcon-root": {
            marginTop: "-2px",
        },
    },
    "& .MuiAutocomplete-root": {
        marginTop: "5px",
        width: width,
        "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            minHeight: "40px!important",
            paddingLeft: 0,
            paddingTop: "5px",
        },
    },
}))
