import React from "react"
import _includes from "lodash/includes"
import _orderBy from "lodash/orderBy"
import _without from "lodash/without"
import _uniq from "lodash/uniq"
import AdSpendOptimizerService from "domain/adspend-optimizer/service/adspend-optimizer.service"
import { LoadResponseDTOReportingDataSetDTO, UpdateResponseDTO } from "generated/models"
import { ColumnConfigDTO, PaginationInfoDTO, ReportingDataSetDTO, AppContextDTO, PageableDTO } from "generated/models"
import { message } from "antd"
import { log } from "shared/util/log"
import Notification from "shared/notification"
import DimensionService from "domain/dimension/service/DimensionService"
import {
    CampaignDTO,
    ComparisonRequestDataDTO,
    DataRowDTO,
    EfficiencyDataDTO,
    EfficiencyRequestDataDTO,
    GridDataRowDTO,
    Scenario,
} from "domain/types"

type ASOContext = {
    allScenarios: Scenario[]
    scenarios: GridDataRowDTO[]
    scenario1: string | number
    scenario2: string | number
    scenario1data: Scenario
    scenario2data: Scenario
    scenarioToEdit: GridDataRowDTO
    scenarioLoading: { loading: boolean; scenarioId: string | number }
    scenariosToPoll: (string | number)[]
    scenarioGridColumns: ColumnConfigDTO[]
    showGrid: number
    comparisonRequestData: ComparisonRequestDataDTO
    comparisonData: ReportingDataSetDTO
    weeklyComparisonData: ReportingDataSetDTO
    efficiencyRequestData: EfficiencyRequestDataDTO
    efficiencyData: EfficiencyDataDTO
    channelData: GridDataRowDTO[]
    subCampaignData: GridDataRowDTO[]
    campaignData: CampaignDTO
    showScenarioForm: boolean
    comparisonOptimizationLevel: string
    appContext: AppContextDTO
    userNotAuthorized: boolean
    invokeFormSubmit: boolean
    appBusy: boolean
    formBusy: boolean
    scenarioGridOrderBy: string
    scenarioGridOrderDirection: string
    scenarioGridPagination: PaginationInfoDTO
    efficiencyChartsLoading: boolean
    historicPerformanceData: ScenarioData[]
    recommendedScenarioData: ScenarioData[]

    handlePageChange: (newPage: number) => void
    defaultScenarioData: (scenario: string) => Scenario
    selectFirstScenario: (scenario: number | string) => void
    selectSecondScenario: (scenario: number | string) => void
    loadPageConfig: () => void
    fetchScenario: (scenarioId: number) => Promise<LoadResponseDTOReportingDataSetDTO>
    fetchScenarios: (campaignId: number, refresh: boolean) => void
    fetchScenarioComparison: () => void
    toggleScenarioGrid: (scenarioNr: number) => void
    toggleScenarioForm: (_, scenarioId?: number) => void
    setChannelData: (data: GridDataRowDTO[]) => void
    setSubCampaignData: (data: GridDataRowDTO[]) => void
    setAppContext: (appContext) => void
    setScenarioData: (nr: number, data: Scenario) => void
    submitForm: () => void
    resetSubmitInvocation: () => void
    hideScenarioGrid: () => void
    updateScenario: (response: LoadResponseDTOReportingDataSetDTO) => void
    deleteScenario: (id: number) => void
    downloadExcel: () => void
    selectScenarioAfterCreation: (data: UpdateResponseDTO) => void
    createScenario: (scenarioData: Scenario) => void
    editScenario: (scenarioData: Scenario) => void
    fetchEfficiencyData: (mediaPlanId: number) => Promise<LoadResponseDTOReportingDataSetDTO>
}

export const AdSpendOptimizerContext = React.createContext({} as ASOContext)

export const HISTORIC_SCENARIO_ID = "historic"
export const RECOMMENDED_SCENARIO_ID = "recommended"
export const ADSPEND_SCENARIO_DIMENSION_IDENTIFIER = "adspend_scenario"
export const START_DATE_DIMENSION_IDENTIFIER = "start_date"
export const END_DATE_DIMENSION_IDENTIFIER = "end_date"
export const JOB_STATUS_DIMENSION_IDENTIFIER = "job_status"
export const MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER = "mediaplan_group_by"
export const COMMENT_DIMENSION_IDENTIFIER = "comment"
export const ADSPEND_MEDIA_PLANS_DIMENSION_IDENTIFIER = "adspend_media_plans"
export const CAMPAIGN_DIMENSION_IDENTIFIER = "campaign"
export const SUB_CAMPAIGN_DIMENSION_IDENTIFIER = "sub_campaign"
export const CHANNEL_DIMENSION_IDENTIFIER = "channel"
export const STATUS_DIMENSION_IDENTIFIER = "status"

export default class AdSpendOptimizerProvider extends React.Component<any, ASOContext> {
    constructor(props) {
        super(props)

        this.state = {
            allScenarios: [],
            scenarios: [],
            showGrid: 0,
            scenario1: HISTORIC_SCENARIO_ID,
            scenario2: RECOMMENDED_SCENARIO_ID,
            scenariosToPoll: [],
            showScenarioForm: false,
            userNotAuthorized: false,
            scenarioLoading: { loading: false, scenarioId: undefined },
            invokeFormSubmit: false,

            scenarioGridOrderBy: DimensionService.getDimensionValueColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER),
            scenarioGridOrderDirection: "DESC",
            scenarioGridPagination: { page: 0, pages: 0, totalEntities: 0, pageSize: 50 },

            defaultScenarioData: this.defaultScenarioData,
            selectFirstScenario: (scenario) => this.selectScenario(1, scenario),
            selectSecondScenario: (scenario) => this.selectScenario(2, scenario),
            loadPageConfig: this.loadPageConfig,
            fetchScenario: this.fetchScenario,
            fetchScenarios: this.fetchScenarios,
            toggleScenarioGrid: this.toggleGrid,
            toggleScenarioForm: (mouseEvent: MouseEvent, scenarioId?: number) => this.toggleScenarioForm(scenarioId),
            setChannelData: this.setChannelData,
            setSubCampaignData: this.setSubCampaignData,
            setAppContext: this.setAppContext,
            fetchScenarioComparison: this.fetchScenarioComparison,
            setScenarioData: this.setScenarioData,
            submitForm: this.submitForm,
            resetSubmitInvocation: this.resetSubmitInvocation,
            handlePageChange: this.handlePageChange,
            hideScenarioGrid: () => this.setState({ showGrid: 0 }),
            updateScenario: this.updateScenario,
            deleteScenario: this.deleteScenario,
            downloadExcel: this.downloadExcel,
            selectScenarioAfterCreation: this.selectScenarioAfterCreation,
            createScenario: (scenario) => this.submitScenario(scenario, "CREATE"),
            editScenario: (scenario) => this.submitScenario(scenario, "UPDATE"),

            // mediaPlanData: [],
            fetchEfficiencyData: this.fetchEfficiencyData,
            historicPerformanceData: undefined,
            recommendedScenarioData: undefined,
            comparisonOptimizationLevel: "CHANNEL",
            efficiencyChartsLoading: false,
        } as ASOContext
    }

    handleApiError = (response: any) => {
        if (response.httpStatus === "FORBIDDEN") {
            this.setState({ userNotAuthorized: true })
        } else {
            if (response.message) {
                message.error(`An error occurred.`, 5)
                console.error(response.message)
                // message.error(`${ response.message }`, 5)
            }
        }
    }

    // for now:
    // handle the case for the demo systems where users can't save or update scenarios and get a message instead
    handleSubmitResponse = (response: UpdateResponseDTO): UpdateResponseDTO => {
        if (response && response.updatedData === null) {
            if (response.response && response.response.messages) {
                const captions = { info: "Info", warn: "Warning", error: "Error" }
                const types: string[] = Object.keys(response.response.messages)
                types.forEach((type) => {
                    response.response.messages[type].forEach((message) => {
                        Notification[type](`${message.title || captions[type]}`, message.message)
                    })
                })
            }
        }
        return response
    }

    setAppContext = (appContext) => {
        log.debug("setting new appContext: ", appContext)
        this.setState(
            (prevState) => ({ ...prevState, appContext }),
            () => {
                if (this.state.appContext && this.state.appContext.campaignId) {
                    this.setState({
                        campaignData: {
                            campaignName: `${
                                appContext.campaignName ? appContext.campaignName : appContext.campaignId
                            }`,
                            campaignId: `${appContext.campaignId}`,
                        },
                        comparisonData: undefined,
                        comparisonRequestData: undefined,
                        scenario1data: undefined,
                        scenario2data: undefined,
                        efficiencyData: undefined,
                        efficiencyChartsLoading: true,
                        channelData: undefined,
                        subCampaignData: undefined,
                        historicPerformanceData: undefined,
                        recommendedScenarioData: undefined,
                    })

                    this.loadPageConfig()
                    this.fetchScenarios(this.state.appContext.campaignId, true)

                    AdSpendOptimizerService.fetchOptimizationLevelData("SUB_CAMPAIGN", this.state.appContext)
                        .then((response: LoadResponseDTOReportingDataSetDTO) => {
                            const { rows } = response.dataSet
                            this.setSubCampaignData(
                                _orderBy(
                                    rows,
                                    [DimensionService.getDimensionNameColumn(SUB_CAMPAIGN_DIMENSION_IDENTIFIER)],
                                    ["asc"],
                                ),
                            )
                        })
                        .catch(this.handleApiError)

                    AdSpendOptimizerService.fetchOptimizationLevelData("CHANNEL", this.state.appContext)
                        .then((response: LoadResponseDTOReportingDataSetDTO) => {
                            const { rows } = response.dataSet
                            this.setChannelData(
                                _orderBy(
                                    rows,
                                    [DimensionService.getDimensionNameColumn(CHANNEL_DIMENSION_IDENTIFIER)],
                                    ["asc"],
                                ),
                            )
                        })
                        .catch(this.handleApiError)

                    this.setState(
                        {
                            scenario1: HISTORIC_SCENARIO_ID,
                            scenario1data: this.defaultScenarioData(HISTORIC_SCENARIO_ID),
                            scenario2: RECOMMENDED_SCENARIO_ID,
                            scenario2data: this.defaultScenarioData(RECOMMENDED_SCENARIO_ID),
                        },
                        () => this.fetchScenarioComparison(true),
                    )
                }
            },
        )
    }

    loadPageConfig = () => {
        AdSpendOptimizerService.loadPageConfig()
            .then((pageConfig) => {
                const columnConfigs = AdSpendOptimizerService.mapColumnConfigs(pageConfig).filter(
                    (c) => c.columnIdentifier !== "status_id",
                )

                this.setState((prevState) => ({
                    ...prevState,
                    userNotAuthorized: false,
                    scenarioGridColumns: columnConfigs,
                }))
            })
            .catch(this.handleApiError)
    }

    fetchScenarios = (campaignId: number, refreshDropdownEntries: boolean = false) => {
        if (this.state) {
            // scenario grid entries
            const { scenarioGridOrderBy, scenarioGridOrderDirection, scenarioGridPagination } = this.state
            const paginationSettings: PageableDTO = {
                page: scenarioGridPagination ? scenarioGridPagination.page : 0,
                pageSize: scenarioGridPagination ? scenarioGridPagination.pageSize : 50,
            }

            const sortSettings = {
                sortProperties: [DimensionService.getDimensionValueColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)],
                sortAscending: false,
            }

            if (scenarioGridOrderBy) sortSettings.sortProperties = [scenarioGridOrderBy]
            if (scenarioGridOrderDirection) sortSettings.sortAscending = scenarioGridOrderDirection === "ASC"

            AdSpendOptimizerService.fetchScenarios(campaignId, this.state.appContext, paginationSettings, sortSettings)
                .then((response: LoadResponseDTOReportingDataSetDTO) => {
                    this.setState((prevState) => ({
                        ...prevState,
                        userNotAuthorized: false,
                        scenarios: response.dataSet.rows,
                        scenarioGridPagination: response.paginationInfo,
                    }))
                })
                .catch(this.handleApiError)

            // refresh dropdown entries
            if (refreshDropdownEntries) {
                const pagination: PageableDTO = Object.assign(
                    { ...paginationSettings },
                    {
                        page: 0,
                        pageSize: 1000,
                    },
                )

                const sortSettings = {
                    sortProperties: [DimensionService.getDimensionValueColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)],
                    sortAscending: false,
                }

                AdSpendOptimizerService.fetchScenarios(campaignId, this.state.appContext, pagination, sortSettings)
                    .then((response: LoadResponseDTOReportingDataSetDTO) => {
                        this.setState((prevState) => ({
                            ...prevState,
                            userNotAuthorized: false,
                            allScenarios: response.dataSet.rows.map((row) =>
                                this.createScenarioDTOFromGridDataRowDTO(row),
                            ),
                        }))
                    })
                    .catch(this.handleApiError)
            }
        }
    }

    selectScenarioAfterCreation = (data: UpdateResponseDTO): void => {
        const scenario: DataRowDTO = data.updatedData.length > 0 ? data.updatedData[0] : undefined
        if (scenario) {
            this.selectScenario(
                2,
                scenario[DimensionService.getDimensionValueColumn(ADSPEND_SCENARIO_DIMENSION_IDENTIFIER)],
            )
            // this.setScenarioData(2, scenario)
        }
    }

    fetchScenario = async (scenarioId: number): Promise<LoadResponseDTOReportingDataSetDTO> => {
        this.setState({ scenarioLoading: { loading: true, scenarioId } })
        const response = await AdSpendOptimizerService.fetchScenario(scenarioId, this.state.appContext).catch(
            (error) => {
                this.handleApiError(error)
                return null
            },
        )
        this.setState({ scenarioLoading: { loading: false, scenarioId: undefined } })
        return response
    }

    updateScenario = (response: LoadResponseDTOReportingDataSetDTO): void => {
        const scenario = response.dataSet.rows[0]
        const { scenariosToPoll } = this.state

        if (scenario) {
            const { scenario1, scenario2 } = this.state

            const scenarioDTO = this.createScenarioDTOFromGridDataRowDTO(scenario)
            scenario[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER].value === scenario1 && this.setScenarioData(1, scenarioDTO)
            scenario[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER].value === scenario2 && this.setScenarioData(2, scenarioDTO)

            // stop polling if processing is done or an error occurred
            if (
                scenario[JOB_STATUS_DIMENSION_IDENTIFIER]?.value === "processed" ||
                _includes(["failed", "disabled"], scenario[JOB_STATUS_DIMENSION_IDENTIFIER]?.value)
            ) {
                this.setState({
                    scenariosToPoll: _without(
                        scenariosToPoll,
                        scenario[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER]?.value,
                    ) as (string | number)[],
                })
            }
        }
    }

    defaultScenarios = [
        { label: "Historic Performance", value: HISTORIC_SCENARIO_ID },
        { label: "Recommended Scenario", value: RECOMMENDED_SCENARIO_ID },
    ]

    defaultScenarioData = (scenarioId: string): Scenario => {
        return {
            adspend_scenario_id: scenarioId,
            adspend_scenario_name: this.defaultScenarios.find((scenario) => scenario.value === scenarioId).label,
            job_status: "processed",
        }
    }

    selectScenario = (scenarioNr: number, scenarioId: number | string): void => {
        const fetch = () => {
            if (typeof scenarioId === "number") {
                this.fetchScenario(scenarioId).then((response) => {
                    const scenarioData = response.dataSet.rows[0]
                    const scenarioDTO = this.createScenarioDTOFromGridDataRowDTO(scenarioData)
                    this.setScenarioData(scenarioNr, scenarioDTO)
                })
            } else {
                this.setScenarioData(scenarioNr, this.defaultScenarioData(scenarioId))
            }
        }

        switch (scenarioNr) {
            case 1:
                this.setState({ scenario1: scenarioId, showGrid: 0 }, fetch)
                break
            case 2:
                this.setState({ scenario2: scenarioId, showGrid: 0 }, fetch)
                break
        }
    }

    createScenarioDTOFromGridDataRowDTO = (gridDataRowDTO: GridDataRowDTO): Scenario => {
        return {
            adspend_scenario_id: gridDataRowDTO[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER]?.value as string | number,
            adspend_scenario_name: gridDataRowDTO[ADSPEND_SCENARIO_DIMENSION_IDENTIFIER]?.name,
            start_date: gridDataRowDTO[START_DATE_DIMENSION_IDENTIFIER]?.value as string,
            end_date: gridDataRowDTO[END_DATE_DIMENSION_IDENTIFIER]?.value as string,
            job_status: gridDataRowDTO[JOB_STATUS_DIMENSION_IDENTIFIER]?.value as string,
            mediaplan_group_by: gridDataRowDTO[MEDIAPLAN_GROUP_BY_DIMENSION_IDENTIFIER]?.value as string,
        }
    }

    setScenarioData = (nr: number, scenario: Scenario): void => {
        const selectedScenarios = [this.state.scenario1, this.state.scenario2]
        let { scenariosToPoll } = this.state

        // check if we have to poll it's data while processing is not finished
        if (
            scenario.job_status &&
            _includes(["unprocessed", "scheduled", "queued", "in_progress"], scenario.job_status) &&
            _includes(selectedScenarios, scenario.adspend_scenario_id)
        ) {
            scenariosToPoll = this.state.scenariosToPoll
                ? _uniq([...this.state.scenariosToPoll, scenario.adspend_scenario_id])
                : [scenario.adspend_scenario_id]
        }

        switch (nr) {
            case 1:
                this.setState({ scenario1data: scenario, scenariosToPoll }, this.fetchScenarioComparison)
                break
            case 2:
                this.setState({ scenario2data: scenario, scenariosToPoll }, this.fetchScenarioComparison)
                break
        }
    }

    deleteScenario = (id: number): void => {
        AdSpendOptimizerService.deleteScenario(`${id}`, this.state.appContext)
            .then(() => this.fetchScenarios(this.state.appContext.campaignId, true))
            .catch(this.handleApiError)
    }

    getMediaPlanType = (): string => {
        let prefix = CHANNEL_DIMENSION_IDENTIFIER

        if (this.state && this.state.scenario1 && this.state.scenario2 && this.state.appContext) {
            const { scenario1, scenario2, scenario1data, scenario2data } = this.state
            try {
                const containsSubCampaignScenario =
                    (scenario1data && scenario1data.mediaplan_group_by === "sub_campaign_id") ||
                    (scenario2data && scenario2data.mediaplan_group_by === "sub_campaign_id")
                const systemScenarios = [RECOMMENDED_SCENARIO_ID, HISTORIC_SCENARIO_ID]
                const scenario1IsSystem = _includes(systemScenarios, scenario1)
                const scenario2IsSystem = _includes(systemScenarios, scenario2)

                const containsCampaignScenario =
                    (scenario1data && scenario1data.mediaplan_group_by === "campaign_id") ||
                    (scenario2data && scenario2data.mediaplan_group_by === "campaign_id")
                const containsOnlySystemScenarios = scenario1IsSystem && scenario2IsSystem
                prefix =
                    containsOnlySystemScenarios || (!containsCampaignScenario && !containsSubCampaignScenario)
                        ? CHANNEL_DIMENSION_IDENTIFIER
                        : SUB_CAMPAIGN_DIMENSION_IDENTIFIER
            } catch (e) {
                console.error(e)
            }
        }

        return prefix
    }

    fetchScenarioComparison = (isInitial: boolean = false) => {
        if (this.state && this.state.scenario1 && this.state.scenario2 && this.state.appContext) {
            const prefix = this.getMediaPlanType()

            this.setState({ comparisonOptimizationLevel: prefix.toUpperCase() }, () => {
                const requestData = {
                    prefix: prefix,
                    scenarioIds: [`${this.state.scenario1}`, `${this.state.scenario2}`],
                    appContext: this.state.appContext,
                }

                const mediaPlans = isInitial
                    ? [CHANNEL_DIMENSION_IDENTIFIER, SUB_CAMPAIGN_DIMENSION_IDENTIFIER]
                    : [prefix]
                const requestPromises: Promise<LoadResponseDTOReportingDataSetDTO>[] = []
                mediaPlans.forEach((mediaPlan) => {
                    requestPromises.push(
                        AdSpendOptimizerService.fetchComparisonData(
                            mediaPlan,
                            requestData.scenarioIds,
                            requestData.appContext,
                        ),
                    )
                })

                Promise.all(requestPromises)
                    .then((responses) => {
                        if (isInitial) {
                            const historicPerformanceData: ScenarioData[] = []
                            const recommendedScenarioData: ScenarioData[] = []

                            responses.forEach((response, index) => {
                                const prefix = mediaPlans[index]
                                const historicData: ScenarioData[] = response.dataSet.rows.map(
                                    (row) =>
                                        ({
                                            mediaPlanType: prefix,
                                            mediaPlanId: row[prefix]?.value,
                                            ...row.slices.data.filter(
                                                (slice) =>
                                                    slice.filter[
                                                        DimensionService.getDimensionValueColumn(
                                                            ADSPEND_SCENARIO_DIMENSION_IDENTIFIER,
                                                        )
                                                    ] === HISTORIC_SCENARIO_ID,
                                            )[0],
                                        }) as ScenarioData,
                                )
                                const recommendedData: ScenarioData[] = response.dataSet.rows.map(
                                    (row) =>
                                        ({
                                            mediaPlanType: prefix,
                                            mediaPlanId: row[prefix]?.value,
                                            ...row.slices.data.filter(
                                                (slice) =>
                                                    slice.filter[
                                                        DimensionService.getDimensionValueColumn(
                                                            ADSPEND_SCENARIO_DIMENSION_IDENTIFIER,
                                                        )
                                                    ] === RECOMMENDED_SCENARIO_ID,
                                            )[0],
                                        }) as ScenarioData,
                                )

                                historicPerformanceData.push(...historicData)
                                recommendedScenarioData.push(...recommendedData)
                            })

                            this.setState({
                                comparisonOptimizationLevel: prefix.toUpperCase(),
                                efficiencyChartsLoading: false,
                                comparisonRequestData: requestData,
                                comparisonData: responses[0].dataSet,
                                userNotAuthorized: false,
                                historicPerformanceData: historicPerformanceData,
                                recommendedScenarioData: recommendedScenarioData,
                            })
                        } else {
                            this.setState({
                                comparisonOptimizationLevel: prefix.toUpperCase(),
                                efficiencyChartsLoading: false,
                                comparisonRequestData: requestData,
                                comparisonData: responses[0].dataSet,
                                userNotAuthorized: false,
                            })
                        }
                    })
                    .catch(this.handleApiError)

                AdSpendOptimizerService.fetchComparisonData(
                    requestData.prefix,
                    requestData.scenarioIds,
                    requestData.appContext,
                    true,
                )
                    .then((response: LoadResponseDTOReportingDataSetDTO) => {
                        this.setState({
                            // weeklyComparisonRequestData: requestData,
                            weeklyComparisonData: response.dataSet,
                            userNotAuthorized: false,
                        })
                    })
                    .catch(this.handleApiError)
            })
        }
    }

    fetchEfficiencyData = async (mediaPlanId: number): Promise<LoadResponseDTOReportingDataSetDTO> => {
        const mediaPlanType = this.getMediaPlanType()
        return await AdSpendOptimizerService.fetchEfficiencyData(mediaPlanType, mediaPlanId, this.state.appContext)
    }

    toggleGrid = (scenarioNr: number): void => {
        this.setState({ showGrid: this.state.showGrid === scenarioNr ? 0 : scenarioNr })
    }

    toggleScenarioForm = (scenarioId?: number): void => {
        if (scenarioId) {
            if (!this.state.showScenarioForm) {
                this.fetchScenario(scenarioId)
                    .then((response: LoadResponseDTOReportingDataSetDTO) => {
                        this.setState({
                            userNotAuthorized: false,
                            scenarioToEdit: response.dataSet.rows[0],
                            showScenarioForm: true,
                            invokeFormSubmit: false,
                        })
                    })
                    .catch(this.handleApiError)
            } else {
                this.setState({ scenarioToEdit: undefined, showScenarioForm: false, invokeFormSubmit: false })
            }
        } else {
            if (this.state.channelData && this.state.subCampaignData)
                this.setState({
                    showScenarioForm: !this.state.showScenarioForm,
                    scenarioToEdit: undefined,
                    invokeFormSubmit: false,
                })
        }
    }

    setChannelData = (channelData: GridDataRowDTO[]): void => {
        this.setState({ channelData })
    }

    setSubCampaignData = (subCampaignData: GridDataRowDTO[]): void => {
        this.setState({ subCampaignData })
    }

    submitForm = (): void => {
        this.setState({ invokeFormSubmit: true })
    }

    handlePageChange = (newPage: number): void => {
        const { scenarioGridPagination } = this.state
        scenarioGridPagination.page = newPage - 1
        this.setState(
            (prevState) => ({
                ...prevState,
                scenarioGridPagination,
            }),
            () => {
                this.fetchScenarios(this.state.appContext.campaignId)
            },
        )
    }

    downloadExcel = (): void => {
        AdSpendOptimizerService.exportComparisonData(this.state.comparisonRequestData).catch(this.handleApiError)
    }

    resetSubmitInvocation = (): void => this.setState({ invokeFormSubmit: false })

    submitScenario = (scenarioData: Scenario, mode: "CREATE" | "UPDATE"): void => {
        this.formBusy(true)

        const submit = (
            scenario: Scenario,
            appContext: AppContextDTO,
            mode: "CREATE" | "UPDATE",
        ): Promise<UpdateResponseDTO> => {
            switch (mode) {
                case "CREATE":
                    return AdSpendOptimizerService.createScenario(scenario, appContext)
                case "UPDATE":
                    return AdSpendOptimizerService.updateScenario(scenario, appContext)
            }
        }

        submit(scenarioData, this.state.appContext, mode)
            // sleep(1500, mockResponse)
            .then(this.handleSubmitResponse)
            .then(this.selectScenarioAfterCreation)
            .then(() => {
                this.fetchScenarios(this.state.appContext.campaignId, true)
            })
            .then(() => this.toggleScenarioForm())
            .then(() => {
                this.resetSubmitInvocation()
                this.formBusy(false)
            })
            .catch(() => {
                this.resetSubmitInvocation()
                this.formBusy(false)
            })
    }

    formBusy = (value: boolean): void => this.setState({ formBusy: value })

    render() {
        return (
            <AdSpendOptimizerContext.Provider value={this.state}>
                {this.props.children}
            </AdSpendOptimizerContext.Provider>
        )
    }
}

export type ScenarioData = SliceData & {
    mediaPlanId: number
    mediaPlanType: string
}

export type SliceData = {
    data: GridDataRowDTO
    filter: {
        "adspend_scenario.value": string | number
    }
}
